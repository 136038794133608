import clsx from 'clsx';
import { HTMLAttributes } from 'react';

// Simple box with padding and a white background for top-level page sections
export default function Tile(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={clsx(
        `p-4 bg-white sm:rounded-none lg:rounded-2xl`,
        !props.className?.includes('overflow-') && 'overflow-visible',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}
