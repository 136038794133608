import mixpanel from 'mixpanel-browser';

const token = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
if (token)
  mixpanel.init(token, {
    track_pageview: true,
  });

let actions = {
  identify: (...args: Parameters<typeof mixpanel.identify>) => {
    if (token) mixpanel.identify(...args);
  },
  register: (...args: Parameters<typeof mixpanel.register>) => {
    if (token) mixpanel.register(...args);
  },
  track: (...args: Parameters<typeof mixpanel.track>) => {
    if (token) mixpanel.track(...args);
  },
  track_pageview: (...args: Parameters<typeof mixpanel.track_pageview>) => {
    if (token) mixpanel.track_pageview(...args);
  },
  reset: (...args: Parameters<typeof mixpanel.reset>) => {
    if (token) mixpanel.reset(...args);
  },
};

export default actions;
