import { useQuery } from '@apollo/client';
import { gql } from 'src/__generated__';

const BOOK_AGAIN_EXPERIMENT_ELIGIBILITY_QUERY = gql(/* GraphQL */ `
  query BookAgainExperimentEligibility {
    me {
      ... on Customer {
        id
        experiments(keys: ["new_book_again"]) {
          key
          value
        }
      }
    }
  }
`);

export default function useNewBookAgainExperimentCohort() {
  const { data, loading } = useQuery(BOOK_AGAIN_EXPERIMENT_ELIGIBILITY_QUERY);
  const hasNewBookAgain =
    data?.me?.__typename === 'Customer' &&
    data?.me?.experiments?.some(
      (exp) => exp?.key === 'new_book_again' && exp.value === 'variant',
    );

  return { hasNewBookAgain, loading };
}
