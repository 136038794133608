import { toast, ToastOptions, DefaultToastOptions } from 'react-hot-toast';

export const UNKNOWN_ERROR =
  'Something went wrong. Please try again or contact our support team';

const customToasts = (message: string, opts?: ToastOptions) => {
  return toast(message, opts);
};
customToasts.error = (message: string, opts?: ToastOptions) => {
  return toast.error(message, opts);
};
customToasts.loading = (message: string, opts?: ToastOptions) => {
  return toast.loading(message, opts);
};
customToasts.success = (message: string, opts?: ToastOptions) => {
  return toast.success(message, opts);
};
customToasts.custom = (message: string, opts?: ToastOptions) => {
  return toast.custom(message, opts);
};
customToasts.dismiss = (toastId?: string) => {
  return toast.dismiss(toastId);
};
customToasts.remove = (toastId?: string) => {
  return toast.remove(toastId);
};
customToasts.promise = (
  promise: Promise<any>,
  msgs: any,
  opts?: DefaultToastOptions,
) => {
  return toast.promise(promise, msgs, opts);
};

export default customToasts;
