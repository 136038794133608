interface BlockTitleProps {
  text: string;
}

const BlockTitle = (props: BlockTitleProps) => {
  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold">{props.text}</h2>
      <div className="w-20 border-2 border-yellow rounded-sm mt-1" />
    </div>
  );
};

export default BlockTitle;
