import { useApolloClient, type FetchPolicy } from '@apollo/client';
import type { AddToBatch } from '../batch';
import { batch, createIntervalScheduler } from '../batch';
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { gql } from 'src/__generated__';

type ExperimentClientContext = {
  fetchFlags?: AddToBatch<unknown, ExperimentRequest>;
};

type ExperimentRequest = {
  key: string;
};

export const EXPERIMENTS_QUERY = gql(/* GraphQL */ `
  query CustomerExperiments($keys: [String!]!) {
    experiments(keys: $keys) {
      key
      value
    }
  }
`);

export const ExperimentContext = createContext(
  Object.create(null) as ExperimentClientContext,
);

// fetch updated flags only once per session so that we don't change the experience
// mid usage
export function ExperimentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [hasFetchedThisSession, updateSession] = useState(false);
  const client = useApolloClient();
  const fetchFlags = useMemo(
    () =>
      batch<unknown | undefined, ExperimentRequest>((requests) => {
        const fetchPolicy: FetchPolicy = hasFetchedThisSession
          ? 'cache-first'
          : 'network-only';
        updateSession(true);
        return client
          .query({
            query: EXPERIMENTS_QUERY,
            fetchPolicy,
            variables: {
              keys: Array.from(new Set(...[requests.map(({ key }) => key)])),
            },
          })
          .then(({ data }) =>
            requests
              .map((flag) => flag.key)
              .map((key) => data.experiments.find((f) => f.key === key)?.value),
          );
      }, createIntervalScheduler()),
    [client, hasFetchedThisSession],
  );

  return (
    <ExperimentContext.Provider value={{ fetchFlags }}>
      {children}
    </ExperimentContext.Provider>
  );
}

// This is used to make sure we don't try to setState in the hook anywhere in
// response to a promise resolution after a component has been unmounted
function useIsMountedRef() {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}

export function useExperiment<T>(
  experimentName: string,
  defaultValue: T,
  skip = false,
): T | undefined {
  const [value, setValue] = useState<T | undefined>();
  const { fetchFlags } = useContext(ExperimentContext);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (skip || !fetchFlags) return;

    fetchFlags({ key: experimentName })
      .then((v) => isMountedRef.current && setValue(v as T))
      .catch(() => isMountedRef.current && setValue(defaultValue));
  }, [fetchFlags, skip, defaultValue, experimentName, isMountedRef]);

  return skip ? defaultValue : value;
}
