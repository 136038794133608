import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PreferredStartWindow } from 'src/__generated__/graphql';
import { parseISO, format, add, formatISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(relativeTime);

/**
 * In case a user doesn't have a TZ, we can attempt to use the browser's
 * TZ (95% supported). This may not be the *most* accurate, but it works.
 * If this fails, we can fall back to the NY timezone.
 */
export const getDefaultTimezone = () => {
  if (typeof Intl !== 'undefined') {
    Sentry.setContext('timezone', {
      browserTz:
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
    });
    return (
      Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York'
    );
  } else {
    Sentry.setContext('timezone', {
      fallbackTz: 'America/New_York',
    });
    Sentry.captureException("Couldn't get timezone from Intl");
    return 'America/New_York';
  }
};

export const getTimezone = () => {
  if (typeof localStorage === 'undefined') return getDefaultTimezone();
  const tz = localStorage.getItem('timezone');
  Sentry.setContext('timezone', { timezone: tz });
  return tz || getDefaultTimezone();
};

export const humanizeDate = (date: Date) => {
  if (typeof localStorage === 'undefined' || !date) return;
  const timezone = getTimezone();
  const d = dayjs(date).tz(timezone);
  return d ? d.format('dddd, MMM D') : undefined;
};

export const fromNow = (date: Date) => {
  if (typeof localStorage === 'undefined') return;

  const timezone = getTimezone();
  return dayjs.tz(date, timezone).fromNow();
};

export const humanizeMonthAndDate = (date: Date) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  return dayjs(date).tz(timezone).format('MMM D');
};

export const humanizeDateTime = (date: Date) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  return dayjs(date).tz(timezone).format('dddd, MMM D hh:mm a');
};

export const humanizeMessageDate = (date: Date) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  return dayjs(date).tz(timezone).format('ddd, MMM D');
};

export const formatTimeAndDuration = (date: Date, duration: number) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  if (!dayjs(date).tz(timezone)) return;
  return `${dayjs(date).tz(timezone).format('h:mm a')} (${duration} hours)`;
};

// adds an hour to the start of appt time
// ex 1:00PM time slot will show 1:00 PM - 2:00 PM
export const getArrivalTimeRangeString = (date: Date) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  if (!dayjs(date).tz(timezone)) return;
  return `${dayjs(date).tz(timezone).format('h:mm a')} - ${dayjs(date)
    .tz(timezone)
    .add(1, 'h')
    .format('h:mm a')}`;
};

export const formatETAString = (date: string) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  if (!dayjs(date).tz(timezone)) return;
  return `${dayjs(date).tz(timezone).format('h:mm a')} - ${dayjs(date)
    .tz(timezone)
    .add(10, 'm')
    .format('h:mm a')}`;
};

export const formatPreferredStartWindow = (
  window?: Omit<PreferredStartWindow, 'priorityMarkupPct'>,
): { date: string; timeRange: string } | undefined => {
  if (
    typeof localStorage === 'undefined' ||
    !window?.startOfWindow ||
    !window?.endOfWindow
  )
    return;
  try {
    const timezone = getTimezone();
    const date = dayjs(window.startOfWindow).tz(timezone).format('dddd, MMM D');
    const startTime = dayjs(window.startOfWindow).tz(timezone).format('h:mm a');
    const endTime = dayjs(window.endOfWindow).tz(timezone).format('h:mm a');
    return {
      date,
      timeRange: `${startTime} - ${endTime}`,
    };
  } catch (e) {
    return undefined;
  }
};

export const formatInTz = (date: Date | string, format: string) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  return dayjs(date).tz(timezone).format(format);
};

export const formatDate = (date: Date | string) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  return dayjs(date).tz(timezone).format('MMMM D, YYYY');
};

export const formatTime = (date: Date) => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  return dayjs(date).tz(timezone).format('hh:mm a');
};

export const formatPhone = (phone?: string) => {
  if (phone) {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(
      6,
      phone.length,
    )}`;
  } else return '';
};

export const startOfDay = () => {
  if (typeof localStorage === 'undefined') return;
  const timezone = getTimezone();
  return dayjs().tz(timezone).startOf('day').toDate();
};

const msInHours = 1000 * 60 * 60;
// negative if in the past
export const getDurationToNowInHours = (date: Date) => {
  const now = +Date.now();
  return (+date - now) / msInHours;
};

export const dateToDayjsTz = (date: Date) => {
  return dayjs(date).tz(getTimezone());
};

export const setTimeForDate = (
  startDate: Date,
  hour: string,
  minute: string,
) => {
  return dayjs(startDate)
    .tz(getTimezone())
    .hour(parseInt(hour))
    .minute(parseInt(minute))
    .toDate();
};

export const getTimeDiffInHours = (startTime: Date, endTime: Date) => {
  return (+endTime - +startTime) / msInHours;
};

/**
 * Combines a date object and a time string into a dayjs object
 * in the user's timezone
 */
export const combineDateAndTimeString = (date: Date, time: string): Date => {
  const tz = getTimezone();
  const dateTime = dayjs(date)
    .tz(tz)
    .hour(parseInt(time.split(':')[0], 10))
    .minute(parseInt(time.split(':')[1], 10))
    .startOf('minute');
  return dateTime.toDate();
};

export const getTimeStringFromDate = (date: Date) => {
  return dayjs(date).tz(getTimezone()).format('HH:mm');
};

export const dateStringInPast = (date: string) => {
  const dateObj = dayjs(date).tz(getTimezone()).toDate();
  return !dayjs().tz(getTimezone()).isBefore(dateObj);
};

export const dateStringIsToday = (date: string) => {
  return dayjs(date).tz(getTimezone()).isSame(dayjs().tz(getTimezone()), 'day');
};

export const isWithinJobHours = (date: string, numHours: number) => {
  const dateObj = dayjs(date).tz(getTimezone());
  const afterStart = !dayjs().tz(getTimezone()).isBefore(dateObj);
  if (!afterStart) return false;
  const isBeforeEnd = dayjs()
    .tz(getTimezone())
    .isBefore(dateObj.add(numHours, 'hours'));
  return isBeforeEnd;
};

export function fromDateWithTz(date: string, tz?: string | null) {
  return tz ? utcToZonedTime(parseISO(date), tz) : parseISO(date);
}

export function formatWithTimezone(
  date: string,
  formatString: string,
  timezone?: string,
) {
  return format(fromDateWithTz(date, timezone), formatString);
}

export function dateWithTime(date: string, timezone?: string) {
  return format(fromDateWithTz(date, timezone), 'MMM d, h aaa');
}

export function dateOnlyTime(date: string, timezone?: string) {
  return format(fromDateWithTz(date, timezone), 'h:mm aaa');
}

export function dateWithoutTime(date: string, timezone?: string) {
  return format(fromDateWithTz(date, timezone), 'MMM d');
}

export function addHours(date: string, hours: number) {
  return formatISO(add(parseISO(date), { minutes: hours * 60 }));
}
