import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';

interface LayoutContext {
  // eslint-disable-next-line no-unused-vars
  setBackButtonAction: (act: any) => void;
  clearBackButtonAction: () => void;
  backButtonAction: undefined | (() => void);
  setWidth: (_width: 'wide' | 'narrow') => void;
  width: 'wide' | 'narrow';
}

const layoutContext = createContext<LayoutContext>({
  setBackButtonAction: () => {},
  clearBackButtonAction: () => {},
  backButtonAction: undefined,
  setWidth: () => {},
  width: 'narrow',
});

// Provider component that wraps the app and makes the layout controls
// available to any child component that calls useLayoutControls().
export function LayoutControlsProvider({ children }: { children: ReactNode }) {
  const ctx = useLayoutControlsProvider();
  return (
    <layoutContext.Provider value={ctx}>{children}</layoutContext.Provider>
  );
}
export const useLayoutControls = () => {
  return useContext(layoutContext);
};

const useLayoutControlsProvider = () => {
  const [backButtonAction, setBackButtonAction] = useState<any>(undefined);
  const [width, setWidth] = useState<'wide' | 'narrow'>('narrow');
  // The use callback here is so that we can use clearBackButtonAction inside of a setBackButtonAction call
  // Without it, we get an infinite useEffect loop
  const clearBackButtonAction = useCallback(() => {
    setBackButtonAction(undefined);
  }, []);
  return {
    setBackButtonAction,
    clearBackButtonAction,
    backButtonAction,
    setWidth,
    width,
  };
};
